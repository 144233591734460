<template>
  <!-- <li v-if="this.value1 !== undefined && this.value2 !== undefined" class="flex justify-content-start align-items-center py-1">
    <div class="hidden sm:flex justify-content-center person-item m-0">
        <small class="muted-text">{{ title }}</small>
    </div>
    <span :class="'flex justify-content-center person-tag ml-auto fg-lightGray p-1 text-sm ' + this.col1" v-tooltip="value1.label"><i :class="'fi ' + icon1 + ' icon-inline text-base mr-1 fg-amber '"></i>{{ value1.value + unit_1 }}</span>
    <span :class="'flex justify-content-center person-tag ml-1 fg-lightGray p-1 text-sm ' + this.col2" v-tooltip="value2.label"><i :class="'fi ' + icon2 + ' icon-inline text-base mr-1 fg-amber'"></i>{{ value2.value + unit_2 }}</span>
  </li> -->
  <Card class="bg-darkGray">
      <template #header>
      </template>
      <template #title>
        <p class="text-sm">{{ value1.label }}</p>
      </template>
      <template #subtitle>
      </template>
      <template #content>
        <div class="flex flex-wrap justify-content-center gap-3">
        <span :class="'flex fg-lightGray p-1 text-sm ' + this.col1" v-tooltip="value1.label"><i :class="'fi ' + icon1 + ' icon-inline text-base mr-1 fg-amber '"></i>{{ value1.value + unit_1 }}</span>
        <span :class="'flex fg-lightGray p-1 text-sm ' + this.col2" v-tooltip="value2.label"><i :class="'fi ' + icon1 + ' icon-inline text-base mr-1 fg-amber '"></i>{{ value2.value + unit_2 }}</span>
      </div>
      </template>
      <template #footer>
      </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "widgetRowValues1",
  setup() {},
  props: {
    title: {
      type: String,
      required: true
    },
    value1: {
      type: null,
      required: false
    },
    icon1: {
      type: String,
      required: false
    },
    color1: {
      type: String,
      required: false
    },
    value2: {
      type: null,
      required: false
    },
    icon2: {
      type: String,
      required: false
    },
    color2: {
      type: String,
      required: false
    }
  },
  watch: {

  },
  computed: {
    col1: function () {
      if (this.color1 !== undefined) {
        return this.color1;
      } else {
        return 'bg-darkOlive';
      }
    },
    col2: function () {
      if (this.color2 !== undefined) {
        return this.color2;
      } else {
        return 'bg-darkOlive';
      }
    },
    unit_1: function () {
      if (this.value1.unit === "#HMS" || this.value1.unit === "#HM") {
        return "";
      } else {
        return this.value1.unit;
      }
    },
    unit_2: function () {
      if (this.value2.unit === "#HMS" || this.value2.unit === "#HM" ) {
        return "";
      } else {
        return this.value2.unit;
      }
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>

</style>